define(['app', 'siteObj', '$window'], function(app, siteObj, $window) {
  
  const countdownCalendar = function() {
    // Build an Array from the server time set in the siteObj.
    const component = {};

    const _init = function(element) {
      component.element = element;

      let serverTime = siteObj.features.countdown.servertime;
      let serverDate = component.parseDate(serverTime);
      let dayParam = component.getParameterByName($window.location.href);
      let serverCurrentDay = serverDate.getDate();
      dayParam = dayParam > serverCurrentDay ? serverCurrentDay : dayParam;
      let userCurrentDay = dayParam ? dayParam : serverCurrentDay;

      component.renderDate(userCurrentDay);
      component.addClass(`[data-day-offer="${userCurrentDay}"]`, 'active');

      if (userCurrentDay === serverCurrentDay) {
        component.showCountdownTimer();
      }
    };

    const _getParameterByName = function(url) {
      const regex = /\?.*day=([1-5]{0,1}[0-9])/;
      const result = url.match(regex);

      return result !== null ? Number(result[1]) : null;
    };

    const _addClass = function(selector, className) {
      const node = component.element.querySelector(selector);

      if (node) {
        app.element.addClass(className, node);
      }
    };

    const _renderDate = function(serverDay) {
      const dayNumber = component.element.querySelector('.countdownCalendar_dateCount_day');
      dayNumber.innerHTML = serverDay;
    };

    const _showCountdownTimer = function() {
      const countdownContainer = component.element.querySelector('[data-countdown-calendar-container]');
      app.element.addClass('countdownCalendar_timerContainer-show', countdownContainer);
      component.handleTileImages(countdownContainer);
    };

    const _handleTileImages = function(el) {
      const container = el.querySelector('.countdownCalendar_timerUnderlay');

      if (!container) return;

      const children = container.querySelectorAll('.countdown-block');
      const imageUrl = container.getAttribute('data-countdown-calendar-image-path');

      for (let i = 0, length = children.length; i < length; i++) {
        children[i].style.backgroundImage = `url(${imageUrl})`;
      }
    };

    const _parseDate = function(serverTime) {
      const presentDate = serverTime.split('-');
      const year = Number(presentDate[0]);
      const month = Number(presentDate[1]) - 1; //This needs to have 1 removed because in javascript
      // months go from 0 - 11
      const date = Number(presentDate[2]);
      const serverDate = new Date(year, month, date); // The time from the server

      return serverDate;
    };

    component.init = _init;
    component.handleTileImages = _handleTileImages;
    component.getParameterByName = _getParameterByName;
    component.addClass = _addClass;
    component.renderDate = _renderDate;
    component.showCountdownTimer = _showCountdownTimer;
    component.parseDate = _parseDate;

    return component;
  };

  return countdownCalendar;

});
